import React from "react";
import clsx from "clsx";

import { Container, Text, Image, Button, ButtonAlt } from "@atoms";
import RibbonsOne from "@svg/RibbonsOne";
import RibbonsTwo from "@svg/RibbonsTwo";
import RibbonsThree from "@svg/RibbonsThree";
import RibbonsFour from "@svg/RibbonsFour";

const Ribbons = ({ variant, color, className: _className }) => {
  switch (variant) {
    case "RibbonsOne":
      return <RibbonsOne color={color} className={_className} />;
    case "RibbonsTwo":
      return <RibbonsTwo color={color} className={_className} />;
    case "RibbonsThree":
      return <RibbonsThree color={color} className={_className} />;
    case "RibbonsFour":
      return <RibbonsFour color={color} className={_className} />;
    default:
      return <RibbonsOne color={color} className={_className} />;
  }
};

const ImageCopy = ({
  // too many params
  back,
  bgColor,
  button,
  className: _className,
  color,
  copy,
  graphic,
  image,
  link,
  order,
  otherButton,
  paddingBottom,
  paddingTop,
  reverse,
  showRibbons,
  subheading,
  variant,
}) => {
  return (
    <section
      className={clsx(
        "relative z-20",
        {
          "bg-white": bgColor === "white",
          "bg-midnight": bgColor === "midnight",
          "md:mt-32": paddingTop && !showRibbons,
          "md:mb-32": paddingBottom && !showRibbons,
          "mb-16 mt-32 md:mb-32": showRibbons && order === 0,
          "mb-16 mt-16": order !== 0,
        },
        _className
      )}
    >
      {showRibbons && (
        <Ribbons variant={variant} color={color} className="relative" />
      )}
      <Container
        className={clsx("relative z-10", {
          "-mt-16 xxs:-mt-12 lg:-mt-32 xl:-mt-48": showRibbons && order === 0,
          "-mt-16 md:-mt-32": showRibbons && order !== 0,
        })}
      >
        <div
          className={clsx(
            "flex flex-wrap justify-between gap-8 md:flex-nowrap md:gap-16",
            {
              "items-center": !showRibbons,
              "items-end": showRibbons,
            }
          )}
        >
          <div
            className={clsx("order-2 flex w-full flex-col md:w-1/2", {
              "md:order-2": !reverse,
              "md:order-1": reverse,
            })}
          >
            <div className="space-y-4">
              {back && (
                <ButtonAlt reverse to={back?.url || "/"}>
                  {back?.text || "Go Back"}
                </ButtonAlt>
              )}
              {subheading && (
                <Text variant="h7" className="mb-4 text-carnation">
                  {subheading}
                </Text>
              )}
              {graphic && (
                <div className="relative mb-8 max-w-[18rem] md:max-w-[24rem]">
                  <Image image={graphic} />
                </div>
              )}
              {copy && (
                <Text
                  richText
                  className={clsx("block", {
                    "prose-dark": bgColor === "midnight",
                    "prose-p:text-lg lg:prose-p:text-xl":
                      showRibbons && order === 0,
                  })}
                >
                  {copy}
                </Text>
              )}
            </div>
            {(button?.url || otherButton?.url || link?.url) && (
              <div className="mt-8 flex items-center space-x-8">
                {button?.url && (
                  <Button size="md" to={button.url}>
                    {button.text}
                  </Button>
                )}
                {otherButton?.url && (
                  <Button size="md" to={otherButton.url} color="white">
                    {otherButton.text}
                  </Button>
                )}
                {link?.url && (
                  <ButtonAlt to={link.url} icon="arrow">
                    {link.text}
                  </ButtonAlt>
                )}
              </div>
            )}
          </div>
          <div
            className={clsx("relative order-1 flex w-1/2 md:w-auto md:flex-1", {
              "md:-mt-24": showRibbons && order !== 0,
              "md:order-1": !reverse,
              "md:order-2": reverse,
            })}
          >
            <div className="relative aspect-square h-full max-h-[28rem] w-full max-w-[28rem]">
              <div className="absolute inset-0 rounded-full">
                <Image
                  image={image}
                  fill
                  className="h-full w-full rounded-full"
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

ImageCopy.defaultProps = {
  back: false,
  showRibbons: false,
  paddingBottom: false,
  paddingTop: false,
};

export default ImageCopy;
